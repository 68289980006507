import React, { useState } from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../../utils/SectionProps";
import ButtonGroup from "../../elements/ButtonGroup";
import Button from "../../elements/Button";
import Modal from "../../elements/Modal";
import Image from "../../elements/Image";
import CustomButtonCalendly from "../../elements/Calendly";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

class LandingHero extends React.Component {
  state = {
    videoModalActive: false,
    isOpen: false,
  };

  openVideoModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: true });
  };

  closeVideoModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: false });
  };

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "hero section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "hero-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const splitClasses = classNames(
      "split-wrap",
      invertMobile && "invert-mobile",
      invertDesktop && "invert-desktop",
      alignTop && "align-top"
    );

    const handleOpen = () => {
      this.setState({
        ...this.state,
        isOpen: true,
      });
    };
    const handleClose = () =>
      this.setState({
        ...this.state,
        isOpen: false,
      });

    return (
      <>
        <CustomButtonCalendly
          isOpen={this.state.isOpen}
          handleClose={handleClose}
        />
        <section {...props} className={outerClasses}>
          <div className="container">
            <div
              className={innerClasses}
              style={{ minHeight: "700px", marginTop: "4rem" }}
            >
              <div className={splitClasses}>
                <div className="split-item">
                  <div className="hero-content split-item-content center-content-mobile reveal-from-top">
                    <h1 className="mt-0 mb-16">
                      Connect, Discover, Deliver !!
                    </h1>
                    <p className="mt-0 mb-32">
                      Your trusted Managed Marketplace for hassle free IT &
                      Digital Delivery
                    </p>
                    <ButtonGroup>
                      <Button
                        tag="a"
                        color="primary"
                        // href="https://cruip.com/"
                        wideMobile
                      >
                        Explore More
                      </Button>
                      <Button
                        tag="a"
                        color="dark"
                        // href="https://cruip.com/"
                        wideMobile
                        onClick={handleOpen}
                      >
                        Connect With Us
                      </Button>
                    </ButtonGroup>
                  </div>
                  <div className="hero-figure split-item-image split-item-image-fill illustration-element-01 reveal-from-bottom">
                    <a
                      data-video="https://player.vimeo.com/video/174002812"
                      href="#0"
                      aria-controls="video-modal"
                      // onClick={this.openVideoModal}
                    >
                      <Image
                        src={"/images/home_banner2.png"}
                        alt="Hero"
                        width={528}
                        height={396}
                      />
                    </a>
                  </div>
                  {/* <Modal
                  id="video-modal"
                  show={this.state.videoModalActive}
                  handleClose={this.closeVideoModal}
                  video="https://player.vimeo.com/video/174002812"
                  videoTag="iframe"
                /> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

LandingHero.propTypes = propTypes;
LandingHero.defaultProps = defaultProps;

export default LandingHero;
