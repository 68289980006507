import React from "react";
import Services from "../components/templates/Services";
import Testimonials from "../components/templates/Testimonials";
import Process from "../components/templates/Process";
import Newsletter from "../components/templates/Newsletter";
import MainServices from "../components/templates/Main Services";
import LandingHero from "../components/templates/LandingHero";
import Clientails from "../components/templates/Clientails";

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <LandingHero hasBgColor invertColor />
        <Clientails topDivider bottomDivider />
        <MainServices />
        <Services topDivider bottomOuterDivider />
        <Process topDivider />
        <Testimonials className="illustration-section-01" />
        <Newsletter invertColor split className="has-bg-color-cut" />
      </React.Fragment>
    );
  }
}

export default Home;
