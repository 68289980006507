import React, { useState } from "react";
import { PopupModal } from "react-calendly";

const CustomButtonCalendly = ({ isOpen, handleClose }) => {
  console.log(isOpen);
  return (
    <div>
      {/* Calendly Popup */}
      <PopupModal
        url="https://calendly.com/lns-orbitsglobal/30min"
        onModalClose={handleClose}
        open={isOpen}
        rootElement={document.getElementById("root")}
        pageSettings={{
          backgroundColor: "ffffff",
          primaryColor: "00a2ff",
          textColor: "4d5055",
        }}
      />
    </div>
  );
};

export default CustomButtonCalendly;
